/*
|-------------------------------------------------------------------------------
| Routes
|-------------------------------------------------------------------------------
| Contains all of the routes for the application
*/

/*
    Imports createRouter and createWebHashHistory to extend with the routes.
*/
import VueRouter from "vue-router";
/*
    Create rotes that we will use to run all of the routes
    for the app.
*/
const ActionCard = () => import("~/views/ActionCard");
const SubscriptionCard = () => import("~/views/SubscriptionCard");
const TransactionCard = () => import("~/views/TransactionCard");
const PaymentCard = () => import("~/views/PaymentCard");
const UnAuthorized = () => import("~/components/UnAuthorized");
const RefundCard = () => import("~/views/RefundCard");
const PauseCard = () => import("~/views/PauseCard");

const routes = [
  {
    path: "/action-card",
    name: "action-card",
    component: ActionCard
  },
  {
    path: "/subscription",
    name: "subscription-card",
    component: SubscriptionCard
  },
  {
    path: "/transaction",
    name: "transaction-card",
    component: TransactionCard
  },
  {
    path: "/payment",
    name: "payment-card",
    component: PaymentCard
  },
  {
    path: "/pause",
    name: "pause-card",
    component: PauseCard
  },
  {
    path: "/refund",
    name: "refund-card",
    component: RefundCard
  },
  {
    path: "/un-authorized",
    name: "un-authorized",
    component: UnAuthorized
  },
  {
    path: "*",
    name: "un-authorized",
    component: UnAuthorized
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

export default router;
