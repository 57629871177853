import Vue from "vue";
import api from "~/services/api";

export const actions = {
  getTransactionProducts: function ({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .getTransactionProducts(data)
        .then(({ data, code, ...res }) => {
          if (code === 200) {
            commit("PUBLIC_KEY", data.pk);
            commit("PRODUCT_LIST", data);
            resolve({ data, code, ...res });
          } else {
            reject({ data, code, ...res });
          }
        })
        .catch((e) => {
          Vue.$toast.error(e?.response?.data?.message || "Unable to do the operation now. Please try later!", {
            timeout: 2000
          });
          reject(e);
        });
    });
  },
  saveTransactionProducts: function ({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .saveTransactionProducts(data)
        .then(({ data, code, ...res }) => {
          if (code === 200) {
            commit("SAVED_TRANSACTION_PRODUCT", data);
            Vue.$toast.success(res.message || "Transaction saved successfully", {
              timeout: 2000
            });
            resolve({ data, code, ...res });
          } else {
            Vue.$toast.error(res?.message || res?.response?.data?.message || "Unable to save transaction", {
              timeout: 2000
            });
            reject({ data, code, ...res });
          }
        })
        .catch((e) => {
          Vue.$toast.error(e?.response?.data?.message || "Unable to save transaction", {
            timeout: 2000
          });
          reject(e);
        });
    });
  },
  saveChargeProducts: function ({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .saveChargeProducts(data)
        .then(({ data, code, ...res }) => {
          if (code === 200) {
            commit("SAVED_TRANSACTION_PRODUCT", data);
            Vue.$toast.success(res.message || "Transaction saved successfully", {
              timeout: 2000
            });
            resolve({ data, code, ...res });
          } else {
            Vue.$toast.error(res?.message || res?.response?.data?.message || "Unable to save transaction", {
              timeout: 2000
            });
            reject({ data, code, ...res });
          }
        })
        .catch((e) => {
          Vue.$toast.error(e?.response?.data?.message || "Unable to save transaction", {
            timeout: 2000
          });
          reject(e);
        });
    });
  },
  /**Subscription Transaction Products */
  getSubscriptionProducts: function ({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .getSubscriptionProducts(data)
        .then(({ data, code, ...res }) => {
          if (code === 200) {
            commit("SUBSCRIBE_TRANSACTION_PRODUCT", data);
            resolve({ data, code, ...res });
          } else {
            reject({ data, code, ...res });
          }
        })
        .catch((e) => {
          Vue.$toast.error(e?.response?.data?.message || "Unable to do the operation now. Please try later!", {
            timeout: 2000
          });
          reject(e);
        });
    });
  },
  getCoupons: function ({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .getCoupons(data)
        .then(({ data, code, ...res }) => {
          if (code === 200) {
            commit("COUPONS", data);
            resolve({ data, code, ...res });
          } else {
            reject({ data, code, ...res });
          }
        })
        .catch((e) => {
          Vue.$toast.error(e?.response?.data?.message || "Unable to do the operation now. Please try later!", {
            timeout: 2000
          });
          reject(e);
        });
    });
  },

  

  getClientSecret: function ({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .getClientSecret(data)
        .then(({ data, code, ...res }) => {
          if (code === 200) {
            commit("CLIENTSECRET", data);
            resolve({ data, code, ...res });
          } else {
            reject({ data, code, ...res });
          }
        })
        .catch((e) => {
          Vue.$toast.error(e?.response?.data?.message || "Unable to do the operation now. Please try later!", {
            timeout: 2000
          });
          reject(e);
        });
    });
  },

  createPaymentIntent: function ({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .createPaymentIntent(data)
        .then(({ data, code, ...res }) => {
          if (code === 200) {
            commit("CREATE_PAYMENT_INTENT", data);
            resolve({ data, code, ...res });
          } else {
            console.log(res.response.data);
            Vue.$toast.error(res?.response?.data?.message || "Unable to do the operation now. Please try later!", {
              timeout: 2000
            });
            reject({ data, code, ...res });
          }
        })
        .catch((e) => {
          Vue.$toast.error(e?.response?.data?.message || "Unable to do the operation now. Please try later!", {
            timeout: 2000
          });
          reject(e);
        });
    });
  },


  saveSubscriptionProducts: function ({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .saveSubscriptionProducts(data)
        .then(({ data, code, ...res }) => {
          if (code === 200) {
            commit("SAVED_SUBSCRIPTION_PRODUCT", data);
            Vue.$toast.success(res.message || "Subscription updated successfully", {
              timeout: 2000
            });
            resolve({ data, code, ...res });
          } else {
            commit("SAVED_SUBSCRIPTION_PRODUCT", data);
            Vue.$toast.error(res?.message || res?.response?.data?.message || "Unable to update subscription", {
              timeout: 2000
            });
            reject({ data, code, ...res });
          }
        })
        .catch((e) => {
          commit("SAVED_SUBSCRIPTION_PRODUCT", data);
          Vue.$toast.error(e?.response?.data?.message || "Unable to update subscription", {
            timeout: 3000
          });
          reject(e);
        });
    });
  },
  /** Products for payments */
  getPaymentProducts: function ({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .getPaymentProducts(data)
        .then(({ data, code, ...res }) => {
          if (code === 200) {
            commit("PAYMENT_PRODUCT", data);
            resolve({ data, code, ...res });
          } else {
            reject({ data, code, ...res });
          }
        })
        .catch((e) => {
          Vue.$toast.error(e?.response?.data?.message || "Unable to do the operation now. Please try later!", {
            timeout: 2000
          });
          reject(e);
        });
    });
  },

  createPaymentLink: function ({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .createPaymentLink(data)
        .then(({ data, code, ...res }) => {
          if (code === 200) {
            commit("PAYMENT_LINK_OBJECT", data);
            Vue.$toast.success(res.message || "Payment link generated successfully", {
              timeout: 2000
            });
            resolve({ data, code, ...res });
          } else {
            Vue.$toast.error(res?.message || res?.response?.data?.message || "Unable to generate payment link.", {
              timeout: 2000
            });
            reject({ data, code, ...res });
          }
        })
        .catch((e) => {
          Vue.$toast.error(e?.response?.data?.message || "Unable to generate payment link.", {
            timeout: 2000
          });
          reject(e);
        });
    });
  },

  createInvoiceLink: function ({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .createInvoiceLink(data)
        .then(({ data, code, ...res }) => {
          if (code === 200) {
            commit("PAYMENT_LINK_OBJECT", data);
            Vue.$toast.success(res.message || "Invoice link generated successfully", {
              timeout: 2000
            });
            resolve({ data, code, ...res });
          } else {
            Vue.$toast.error(res?.message || res?.response?.data?.message || "Unable to generate invoice link.", {
              timeout: 2000
            });
            reject({ data, code, ...res });
          }
        })
        .catch((e) => {
          Vue.$toast.error(e?.response?.data?.message || "Unable to generate invoice link.", {
            timeout: 2000
          });
          reject(e);
        });
    });
  },

  authenticate: function ({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .authenticate(data)
        .then(({ data, code, ...res }) => {
          if (code === 200) {
            commit("AUTHENTICATION", data);
            resolve({ data, code, ...res });
          } else {
            Vue.$toast.error(res.message, {
              timeout: 2000
            });
            reject({ data, code, ...res });
          }
        })
        .catch((e) => {
          Vue.$toast.error(e?.response?.data?.message || "Authentication Failed", {
            timeout: 2000
          });
          reject(e);
        });
    });
  },
  getRefundableSubscription: function ({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .getRefundableSubscription(data)
        .then(({ data, code, ...res }) => {
          if (code === 200) {
            commit("REFUND_PRODUCTS", data);
            resolve({ data, code, ...res });
          } else {
            reject({ data, code, ...res });
          }
        })
        .catch((e) => {
          Vue.$toast.error(e?.response?.data?.message || "Unable to do the operation now. Please try later!", {
            timeout: 2000
          });
          reject(e);
        });
    });
  },
  refund: function ({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .refund(data)
        .then(({ data, code, ...res }) => {
          if (code === 200) {
            commit("REFUND", data);
            resolve({ data, code, ...res });
          } else {
            reject({ data, code, ...res });
          }
        })
        .catch((e) => {
          Vue.$toast.error(e?.response?.data?.message || "Unable to do the operation now. Please try later!", {
            timeout: 2000
          });
          reject(e);
        });
    });
  },
  getListForPause: function ({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .getListForPause(data)
        .then(({ data, code, ...res }) => {
          if (code === 200) {
            commit("LIST_FOR_PAUSE", data);
            resolve({ data, code, ...res });
          } else {
            reject({ data, code, ...res });
          }
        })
        .catch((e) => {
          Vue.$toast.error(e?.response?.data?.message || "Unable to do the operation now. Please try later!", {
            timeout: 2000
          });
          reject(e);
        });
    });
  },
  pauseSubscription: function ({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .pauseSubscription(data)
        .then(({ data, code, ...res }) => {
          if (code === 200) {
            commit("PAUSE", data);
            resolve({ data, code, ...res });
          } else {
            reject({ data, code, ...res });
          }
        })
        .catch((e) => {
          Vue.$toast.error(e?.response?.data?.message || "Unable to do the operation now. Please try later!", {
            timeout: 2000
          });
          reject(e);
        });
    });
  },
  getSavedPaymentsMethod: function ({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .getSavedPaymentsMethod(data)
        .then(({ data, code, ...res }) => {
          if (code === 200) {
            commit("SAVED_PAYMENT_LIST", data);
            resolve({ data, code, ...res });
          } else {
            reject({ data, code, ...res });
          }
        })
        .catch((e) => {
          Vue.$toast.error(e?.response?.data?.message || "Unable to do the operation now. Please try later!", {
            timeout: 2000
          });
          reject(e);
        });
    });
  },
  getAccounts: function ({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .getAccounts(data)
        .then(({ data, code, ...res }) => {
          if (code === 200) {
            commit("LIST_ACCOUNTS", data);
            resolve({ data, code, ...res });
          } else {
            Vue.$toast.error(res.message, {
              timeout: 2000
            });
            reject({ data, code, ...res });
          }
        })
        .catch((e) => {
          Vue.$toast.error(e?.response?.data?.message || "Request Failed, please contact support", {
            timeout: 2000
          });
          reject(e);
        });
    });
  },
  sendMetricts: function ({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .sendMetricts(data)
        .then(({ data, code, ...res }) => {
          if (code === 200) {
            commit("SEND_METRICS", data);
            resolve({ data, code, ...res });
          } else {
            Vue.$toast.error(res.message, {
              timeout: 2000
            });
            reject({ data, code, ...res });
          }
        })
        .catch((e) => {
          Vue.$toast.error(e?.response?.data?.message || "Metricts Request Failed", {
            timeout: 2000
          });
          reject(e);
        });
    });
  },
};
