import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";
import router from "~/router";
import vSelect from "vue-select";
import vModal from "vue-js-modal";
import store from "~/store";
import "@fortawesome/fontawesome-free/js/all.min.js";
import { BootstrapVue, IconsPlugin, SpinnerPlugin  } from "bootstrap-vue";
import Toast from "vue-toastification";
import VueClipboard from "vue-clipboard2";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

import "~/assets/scss/index.scss";

Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.component("v-select", vSelect);
Vue.use(vModal);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(SpinnerPlugin);
Vue.use(require('vue-moment'));

VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

const options = {
  timeout: 2000,
};
Vue.use(Toast, options);

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_DSN,
  //dsn: 'https://049f56773fed4409a32dde74bf9a8c16@o1064206.ingest.sentry.io/6303102',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", process.env.VUE_APP_BASE_URL, /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

new Vue({
  render: (h) => h(App),
  store,
  router,
}).$mount("#app");
